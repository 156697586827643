.cookies-wrapper {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    background-color: #F7F4EF;
    z-index: 1;
    box-shadow: 1px 1px 20px 6px rgb(168 165 165);
}

.cookies-wrapper p {
    color: #111;
}

.cookies-link {
    margin-left: 5px;
    text-decoration: underline;
}

.cookies-link:hover {
    color: #4f5e0f;
}

.cookies-wrapper button {
    padding: 0px 10px;
}

.cookies-button {
    margin-left: 10px;
}

@media screen and (max-width: 780px) {
    .cookies-wrapper {
        margin: auto;
    }
}