.newsletter {
    padding: 20px 0;
}

.newsletter-subtitle {
    margin: 0;
}

.contact-heading p {
    font-weight: 600;
}

.newsletter-form {
    display: flex;
    justify-content: center;
}

.form-group {
    display: flex;
}

.form-input {
    padding: 20px;
}

.form-control {
    border: none;
    border-bottom: 1px solid lightgray;
    width: 20rem;
}

.newsletter-button {
    border: none;
    background: none;
}

.newsletter-icon {
    color: #9bb037;
    font-size: 1.3rem;
}

@media screen and (max-width: 767px) {
    .newsletter-form {
        flex-direction: column;
    }

    .form-group {
        flex-direction: column;
    }

    .form-input {
        padding: 0px 20px;
    }

    .form-control {
        border: none;
        border-bottom: 1px solid lightgray;
        width: 100%;
    }
}