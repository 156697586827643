#banner-wrapper {
    background: url("../../assets/images/banner-images/nancy-banner.png") no-repeat center;
    background-size: cover;
}

.banner-wrapper {
    
    padding: 55px 0;
}

#banner h2 {
    font-weight: 600;
}

@media screen and (max-width: 1024px) {
    #banner-wrapper {
        background: url("../../assets/images/banner-images/nancy-banner-min.png") no-repeat center;
    }
}

@media screen and (max-width: 480px) {
    .banner-text h1 {
        font-size: 35px !important;
    }
}

@media screen and (max-width: 400px) {
    .banner-text h1 {
        font-size: 30px !important;
        line-height: 40px !important;
    }
}