/* .beige-bg {
    background-color: #F7F4F3;
} */

.about-me {
    display: flex;
    align-items: center;
}

.about-img {
    max-width: 70% !important;
    padding: 25px 25px;
    position: relative;
}

.about-img::before {
    position: absolute;
    /* top: 15px; */
    left: 40px;
    bottom: 10px;
    content: "";
    background: #ced79e82;
    width: 92%;
    height: 92%;
    z-index: -1;
}


@media screen and (max-width: 767px) {
    .about-me {
        flex-direction: column;
    }
}