.contact {
    display: flex;
    flex-direction: column;
}

.contact-heading h3 {
    text-transform: uppercase;
}

.contact-col {
    padding: 30px;
}

.contact-intro {

}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-inputs {
    display: flex;
    flex-direction: column;
}

.contact-form-wrapper {
    display: flex;
    text-align: left;
}

.contact-form-wrapper ul li{
    padding-bottom: 15px;
}

.button-style {
    text-transform: uppercase;
    text-align: center;
}

.socials-contact a {
    margin-right: 30px;
}

textarea {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica !important;
}

@media screen and (max-width: 767px) {
    .contact-form-wrapper {
        flex-direction: column;
    }
}