.pricing-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.price-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 300px;
}
/* .price-item h3 {
    margin: 0 0 25px 0 !important;
} */

.price-item p {
    margin: 0 !important;
}

.price-button {
    margin: 0px !important;
}