.faqs-section {
    padding: 75px 0;
}

.faqs-title {
    text-transform: uppercase;
}

.faq-question div {
    color: #575757;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.faq-answer {
    text-align: left;
}
.accordion-item {
    border-radius: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    background-color:  #FFF;
    color: #A8BA55;
    box-shadow: none;
}

/* .accordion .accordion-button:focus {
    box-shadow: none !important;
} */

/* .accordion-body {
    font-size: 1rem;
}

.accordion-body a:hover {
    color: #5b9879;
} */

.faq-answer p, strong {
    color: #575757;
}

.faq-answer strong {
    color: #575757 !important;
    font-size: 0.9rem !important;
}

.faq-answer a {
    text-decoration: underline;
}

.faq-answer a:hover {
    color: #5b9879;
}