/* Namari Landing Page Dynamic Style Index

1. Website Default Styling
2. Navigation
3. Primary and Secondary Colors
4. Banner
5. Typography
6. Buttons
7. Footer


/*------------------------------------------------------------------------------------------*/
/* 1. Website Default Styling */
/*------------------------------------------------------------------------------------------*/


body {
    background:#fff;
}


/* Default Link Color */

a, .la-ball-triangle-path {
    color:#A8BA55;
}

a:hover, #header.nav-solid nav a:hover {
    color: #a4bb3e;
}

/* Default Icon Color */

.icon i {
    color:#A8BA55;
}

.icon svg {
    color:#A8BA55;
}

/* Border Color */

#banner .section-heading:before, .testimonial.classic footer:before {
    background: #A8BA55;
}

.pricing-block-content:hover {
    border-color:#A8BA55;
}


/*------------------------------------------------------------------------------------------*/
/* 2. Navigation */
/*------------------------------------------------------------------------------------------*/


/* Transparent Navigation Color on a Banner */

#header nav a, #header i {
    color:#111;
}


/* Navigation Colors when the Navigation is sticky and solid */ 

#header.nav-solid, #header.nav-solid a, #header.nav-solid i, #nav-mobile ul li a {
    color:#333;
}


/* Navigation Active State */

#header.nav-solid .active {
    color: #A8BA55;
    border-color: #A8BA55;
}    



/*------------------------------------------------------------------------------------------*/
/* 3. Primary and Secondary Colors */
/*------------------------------------------------------------------------------------------*/


/* Primary Background and Text Colors */

.primary-color, .featured .pricing {
    background-color:#CED79E;
}

.primary-color, .primary-color .section-title, .primary-color .section-subtitle, .featured .pricing, .featured .pricing p {
    color:#fff;    
}

.section-heading h2:after {
    background: #a8ba55;
    content:"";
    display:block;
    width:30px;
    height:5px;
    margin-top:30px;
}

.text-center .section-heading h2:after {
    margin:30px auto 25px auto;
}

/* Primary Icon Colors */

.primary-color .icon i, .primary-color i {
    color:#fff;
}


/* Secondary Background and Text Colors */

.secondary-color {
    background-color:#f5f5f5;
}

.beige-bg1 {
    background-color: #FBFAF8;
}

.beige-bg2 {
    background-color: #F7F4F3;
}




.beige-bg {
    background-color: #F7F4EF;
}

.green-accent {
    background-color: #A8BA55;
}

.silver-tree {
    background-color: #74BA98;
}

.dark-silver-tree {
    background-color: #57A37D;
}

.sprout {
    background-color: #ACCE9F;
}

.light-winter-hazel {
    background-color: #ced79e82;
}

.winter-hazel {
    background-color: #CED79E;
}

.kidnapper {
    background-color: #DFE7CC;
}

.aqua-spring {
    background-color: #F2FBF8;
}

.dark-green {
    background-color: #4f5e0f;
}

/* .beige-bg3 {
    background-color: #F3F0EA;
}

.beige-bg3 {
    background-color: #EDE8DF;
}

.beige-bg3 {
    background-color: #F7F5EF;
} */




/*------------------------------------------------------------------------------------------*/
/* 4. Banner */
/*------------------------------------------------------------------------------------------*/


/* Banner Background and Text Colors */

/* #banner {
    background: url("../images/banner-images/nancy-banner.png") no-repeat right;
    background-size: contain;
} */


/*------------------------------------------------------------------------------------------*/
/* 5. Typography */
/*------------------------------------------------------------------------------------------*/


body {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size:15px;
    font-weight:normal;
    color:#111;
}

/* Logo, if you are using Fonts as Logo and not image

#logo h1 {
    font-family:;
    font-size:; 
    font-weight:;
    color:;
}

#logo h2 {
    font-family:;
    font-size:; 
    font-weight:;
    color:;
}

*/


/* Banner Typography */

#banner h1 {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size:62px;
    line-height:60px;
    font-weight:800;
    color:#111;
}

#banner h2 {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size:18px;
    font-weight:300;
    color:#111;
}


/* Section Title and Subtitle */

.section-title {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 34px; 
    font-weight:700;
    color:#111;
}

.section-subtitle {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 16px;
    font-weight:300;
    color: #333333;
}

/* Testimonial */

.testimonial q {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 17px; 
    font-weight:300;
}

.testimonial.classic q, .testimonial.classic footer {
    color:#111;
}


/* Standard Headings h1-h6 */

h1 {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 40px; 
    font-weight:300;
    color:#111;
}

h2 {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 34px; 
    font-weight:300;
    color:#111;
}

h3 {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 30px; 
    font-weight:700;
    color:#111;
}

h4 {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 18px; 
    font-weight:400;
    color:#111;
}

h5 {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 16px; 
    font-weight:400;
    color:#111;
}

h6 {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 14px; 
    font-weight:400;
    color:#111;
}


/*------------------------------------------------------------------------------------------*/
/* 6. Buttons */
/*------------------------------------------------------------------------------------------*/

/* ----------Default Buttons---------- */


/* Button Text */

.button, input[type="submit"]  {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size:14px;
    font-weight:bold;
    /* color:#111; */
    color: #ffffff;
    background-color: #a8ba5599;
}


/* Button Color */

.button, input[type="submit"] {
    /* border-color:#111; */
    border-color: #a8ba55;
}


/* Button Hover Color */

.button:hover,  input[type="submit"]:hover {
    /* border-color:#b2bc75;
    color:#b2bc75; */
    border-color:#a8ba5552;
    color:#fff;
    background-color: #a8ba55;
}


/* ----------Banner Buttons---------- */


/* Button Text */

#banner .button {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size:16px;
    color:#ffffff;
}


/* Button Color */

#banner .button {
    border-color:#7d9218;
}


/* Button Hover Color */

#banner .button:hover {
    border-color:#a8ba5552;
    color:#fff;
    background-color: #a8ba55c9;
}


/*------------------------------------------------------------------------------------------*/
/* 7. Footer */
/*------------------------------------------------------------------------------------------*/

#landing-footer, #landing-footer p, #landing-footer a {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size:12px;
    font-weight:normal;
    color:#111;
}

/* Footer Icon Color */

#landing-footer i {
    color:#111;
}