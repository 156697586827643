.logo-banner {
    max-height: 50px !important;
    width: 100%;
}

.nav-logo {
    max-height: 50px !important;
    width: 100%;
}

@media screen and (max-width: 480px) {
    .nav-logo {
        max-height: 50px !important;
        width: 60%;
        margin-right: 0px;
    }
}