.scroll {
    position: fixed; 
    z-index: 2147483647; 
    overflow: hidden; 
}

#scrollUp.hidden {
    display: none;
}

#scrollUp.visible {
    display: block;
    /* additional styles for the visible state if needed */
}